body {
  background-color: #9FA1A7;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #556781;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #F2F3F0;
  margin-bottom: 0;
}

.App-header__Link {
  text-decoration: none;
}

@media only screen and (min-width: 800px) {
  main {
    width: 800px;
    margin: auto;
    min-height: 200vh;
  }
}

nav {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #85858B;
  margin-top:0px;
  margin-bottom: 0px;
  min-height: 30px;
  line-height: 30px;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Link {
  color: #556781;
  background-color: #F2F3F0;
  border: solid #556781 1px;
  border-radius: 10px; 
  margin: 2px;
}

nav .Link {
  padding-left: 1vw;
  padding-right: 1vw;
  text-transform: uppercase;
  color: #556781;
}

.review {
  background-color: #F2F3F0;
  border: 3px solid #85858B;
  margin: 10px;
  margin-top: 40px;
}

.review img {
  width: 100%;
}

.errorMessage {
  color: red
}

.successMessage {
  color: green
}

.comments {
  list-style-type: none;
  padding-left: 0;
}

.comments__Delete {
  margin-bottom: 3px;
  font-size: 0.9em;
}

#sort_by {
  margin-top: 10px;
}

#order_by {
  margin-top: 10px;
  margin-left: 10px;
}

.comments li {
  background-color: #F2F3F0;
  border: 3px solid #85858B;
  margin: 10px;
}

button {
  background-color: #9FA1A7;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 5px;
  font-size: 1.2em;
}

.login {
  float: right;
  margin-right: 20px;
  margin-top: 5px;
  background-color: #F2F3F0;
}

.reviewList {
  list-style-type: none;
  padding-left: 0;
}

.reviewList li {
  background-color: #F2F3F0;
  border: 3px solid #85858B;
  margin: 10px;
}

.newReview {
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #F2F3F0;
  border: 3px solid #85858B;
  margin: 10px;
  display: grid
}

.newReview textarea {
  resize: none;
  padding-top: 5px;
  margin-bottom: 10px;
}

.newReview__Category {
  margin-bottom: 10px;
}

.reviewList__Header {
  border: 2px solid #85858B;
  margin: 5px;
  height: auto;
}

.reviewList__Header__Title {
  color: #556781
}

.review__Title {
  color: #556781
}

.reviewList__Header__Category {
  text-transform: capitalize;
}

.reviewList__Body {
  border: 2px solid #85858B;
  margin: 5px;
}

.reviewList__Body p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.newComment button{
  font-size: 0.9em;
  margin-left: 10px;
}

.newComment__Field {
  resize: none;
  height: 12vh;
  width: 75%;
  margin-bottom: -2px;
}